import { ReferenceItem } from "@/services/types";

export enum NotificationType {
  EXPOSE_ORDERED = "EXPOSE_ORDERED",
  NEW_CONNECTION = "NEW_CONNECTION",
  TEASER_ACCEPTED = "TEASER_ACCEPTED",
  SEARCH_PROFILE_CREATED = "SEARCH_PROFILE_CREATED",
  CONTACT_NDA_SIGNED = "CONTACT_NDA_SIGNED",
  CONTACT_NDA_SENT = "CONTACT_NDA_SENT",
  GDRIVE_DISCONNECTED = "GDRIVE_DISCONNECTED",
  BEXIO_DISCONNECTED = "BEXIO_DISCONNECTED",
  EXPRESSION_OF_INTEREST_SENT = "EXPRESSION_OF_INTEREST_SENT",
  EXPRESSION_OF_INTEREST_FILLED = "EXPRESSION_OF_INTEREST_FILLED",
  EXPRESSION_OF_INTEREST_CHANGED = "EXPRESSION_OF_INTEREST_CHANGED",
  CONTACT_DISABLED = "CONTACT_DISABLED",
  CONTACT_DISABLED_REGISTRATION = "CONTACT_DISABLED_REGISTRATION",
  EXPOSE_REJECTED = "EXPOSE_REJECTED",
  CONTACT_DUPLICATE_EXPOSE_ORDER = "CONTACT_DUPLICATE_EXPOSE_ORDER",
}

export const notificationTypeToTranslationMap: Record<
  NotificationType,
  string
> = {
  [NotificationType.NEW_CONNECTION]: "notifications.newConnection",
  [NotificationType.EXPOSE_ORDERED]: "notifications.newPotentialBuyer",
  [NotificationType.SEARCH_PROFILE_CREATED]:
    "notifications.searchProfileCreated",
  [NotificationType.CONTACT_NDA_SIGNED]: "notifications.ndaSigned",
  [NotificationType.CONTACT_NDA_SENT]: "notifications.ndaSent",
  [NotificationType.CONTACT_DISABLED]: "notifications.contactDisabled",
  [NotificationType.CONTACT_DISABLED_REGISTRATION]:
    "notifications.contactDisabledRegistration",
  [NotificationType.TEASER_ACCEPTED]: "notifications.teaserAccepted",
  [NotificationType.GDRIVE_DISCONNECTED]: "notifications.gDriveDisconnected",
  [NotificationType.BEXIO_DISCONNECTED]: "notifications.bexioDisconnected",
  [NotificationType.EXPRESSION_OF_INTEREST_SENT]: "notifications.eoiSent",
  [NotificationType.EXPRESSION_OF_INTEREST_FILLED]: "notifications.eoiFilled",
  [NotificationType.EXPRESSION_OF_INTEREST_CHANGED]: "notifications.eoiChanged",
  [NotificationType.EXPOSE_REJECTED]: "notifications.exposeRejected",
  [NotificationType.CONTACT_DUPLICATE_EXPOSE_ORDER]:
    "notifications.contactDuplicateExposeOrder",
};

export default interface Notification {
  id?: number;
  historyId: number;
  userId: number;
  buyerId: number | null;
  dismissed: boolean;
  important: boolean;
  createdAt: number;
  project: ReferenceItem | null;
  contact: ReferenceItem | null;
  type: NotificationType;
  meta: string | null;
}
